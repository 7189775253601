import React from "react";
import { MdAccountCircle } from "react-icons/md";
import { useEffect, useState } from "react";

import logo from "../../assets/images/logo-1.png";
import logo1 from "../../assets/images/logo-2.png";
import { useTheme } from "../../ThemeContext";

import { BiLogoInstagramAlt } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { TbBrandYoutubeFilled } from "react-icons/tb";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

export default function Footer() {
  const { theme } = useTheme();

  return (
<>
      <div className="bg-base-300 mt-10 pt-4">
        <div className="max-w-screen-2xl px-4 sm:px-8  sm:grid md:grid-cols-4 sm:grid-cols-2 mx-auto">
          <div className="p-5">
          <aside className="items-center grid-flow-col">
            {theme === "dark" && (
              <img src={logo} alt="" width="120" height="120" />
            )}
            {theme !== "dark" && (
              <img src={logo1} alt="" width="120" height="120" />
            )}
            {/* <a className="btn bg-none	 text-xl px-0" href="#">Skillzyy</a> */}
          </aside>
          </div>
          <div className="p-5">
            <div className="text-sm uppercase text-pink-600 font-bold">Resources</div>
            <a className="my-3 block hover:underline" href="/#">Documentation <span className="text-yellow-500 text-xs p-1"></span></a>
            <a className="my-3 block hover:underline" href="/#">Tutorials <span className="text-yellow-500 text-xs p-1"></span></a>
            <a className="my-3 block hover:underline" href="/#">Support <span className="text-yellow-500 text-xs p-1">New</span></a>
          </div>
          <div className="p-5">
            <div className="text-sm uppercase text-pink-600 font-bold">Support</div>
            <a className="my-3 block hover:underline" href="/#">Help Center <span className="text-yellow-500 text-xs p-1"></span></a>
            <a className="my-3 block hover:underline" href="/#">Privacy Policy <span className="text-yellow-500 text-xs p-1"></span></a>
            <a className="my-3 block hover:underline" href="/#">Conditions <span className="text-yellow-500 text-xs p-1"></span></a>
          </div>
          <div className="p-5">
            <div className="text-sm uppercase text-pink-600 font-bold">Contact us</div>
            <a className="my-3 block hover:underline" href="tel:+917752836866">+91-77528-36866<span className="text-yellow-500 text-xs p-1"></span></a>
            <a className="my-3 block hover:underline" href="mailto:learn@skillzyy.in" >learn@skillzyy.in<span className="text-yellow-500 text-xs p-1"></span></a>
          </div>
        </div>
      </div>
      <div className="bg-base-300 pt-2">
        <div className="flex pb-5 px-3 m-auto pt-5 border-t border-base-100 text-gray-600 text-sm flex-col max-w-screen-xl items-center">
          <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
          
            <a href="/#" className="mx-2 hover:text-pink-600 shadow-xl transition duration-200 hover:scale-150 ">
              <FaTelegramPlane className="w-6 h-6"/>
            </a>
            <a href="/#" className= "mx-2 hover:text-pink-600 shadow-xl transition duration-200  hover:scale-150">
              <FaXTwitter className="w-6 h-6"/>
            </a>
            <a href="/#" className="mx-2 hover:text-pink-600 shadow-xl transition duration-200  hover:scale-150">
              <TbBrandYoutubeFilled className="w-6 h-6"/>
            </a>
            <a href="https://www.instagram.com/_skillzyy/" target="_blank" className= "mx-2 hover:text-pink-600 shadow-xl transition duration-200 hover:scale-150">
              <BiLogoInstagramAlt className="w-6 h-6"/>
            </a>
            <a href="/#" className="mx-2 hover:text-pink-600 shadow-xl transition duration-200  hover:scale-150">
              <FaFacebook className="w-6 h-6"/>
            </a>
            <a href="/#" className="mx-2 hover:text-pink-600 shadow-xl transition duration-200  hover:scale-150">
              <FaLinkedinIn className="w-6 h-6"/>
            </a>
            <a href="/#" className= "mx-2 hover:text-pink-600 shadow-xl transition duration-200  hover:scale-150">
              <IoLogoWhatsapp className="w-6 h-6"/>
            </a>



    
            {/* Add other social media icons here */}
          </div>
          <div className="my-5">&copy; {new Date().getFullYear()} All Rights Reserved.</div>
        </div>
      </div>
    </>
  );
}
