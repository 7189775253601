import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-1.png";
import logo1 from "../../assets/images/logo-2.png";
import { useTheme } from "../../ThemeContext";
import { Link } from "react-router-dom";

export default function Navbar() {


  const { theme, updateTheme } = useTheme();
  const [isChecked, setIsChecked] = useState(theme === "cupcake");
  const [showCoursesDropdown, setShowCoursesDropdown] = useState(false);


  useEffect(() => {
    setIsChecked(theme === "cupcake");
  }, [theme]);

  const handleToggle = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    updateTheme(checked ? "cupcake" : "dark");
  };


  const toggleCoursesDropdown = () => {
    setShowCoursesDropdown(!showCoursesDropdown);
  };

  return (
    <div>
      <div className="navbar bg-base-200 sm:px-10 py-4 fixed z-10">
        <div className="navbar-start ">
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1]  shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/">Courses</Link>
              </li>
              
              <li>
                <Link to="/services">Our Services</Link>
                <svg
                  className="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </li>

              <li>
                <Link to="/school-contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <aside className="items-center grid-flow-col">
            {theme === "dark" && (
              <img src={logo} alt="" width="120" height="120" />
            )}
            {theme !== "dark" && (
              <img src={logo1} alt="" width="120" height="120" />
            )}
            {/* <a className="btn bg-none	 text-xl px-0" href="#">Skillzyy</a> */}
          </aside>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About us</Link>
            </li>
            <li className="relative">
              <button onClick={toggleCoursesDropdown}>Courses</button>
              {showCoursesDropdown && (
                <div className="absolute bg-base-100 rounded mt-10 ">
                <ul className="flex flex-col gap-4">
                  <li>Course 1</li>
                  <li>Course 2</li>
                  <li>Course 3</li>
                  <li>Course 4</li>
                </ul>
              </div>
              )}
            </li>
            <li>
              <Link to="/services">Our Services</Link>
            </li>
            <li>
              <Link to="/school-contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="navbar-end pr-4">
          <a className="btn">
            <label className="swap swap-rotate">
              {/* this hidden checkbox controls the state */}
              <input
                type="checkbox"
                onChange={handleToggle}
                checked={isChecked}
              />

              {/* sun icon */}
              <svg
                className="swap-on fill-current w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
              </svg>

              {/* moon icon */}
              <svg
                className="swap-off fill-current w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
              </svg>
            </label>
          </a>
        </div>
      </div>
    </div>
  );
}
