import React from 'react'
import Services from '../components/services/Services'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'


export default function ServicesPage() {
  return (
    <div>
      <Navbar />
      <Services/>
      <Footer />

    </div>
  )
}
