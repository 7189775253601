import React from "react";

const Testimonial = () => {
  const slides = [
    {
      id: 1,
      heading: "Inspiring Presentation",
      desc: "I found the presentation truly inspiring and informative. It provided valuable insights into various topics.",
      p_name: "Ziqra Khan",
      profession: "Student at A.M.B.M Public School",
    },
    {
      id: 2,
      heading: "Engaging Content",
      desc: "Skillzyy has been a game-changer for me! The workshops were so engaging and hands-on, and I learned practical skills that I can actually use in the real world. Plus, the instructors were super supportive and made learning fun. I feel more confident and ready to tackle whatever comes my way. Thank you, Skillzyy!",
      p_name: "Sujal Kumar",
      profession: "Student at Mother Teresa School",
    },
    {
      id: 3,
      heading: "Informative Session",
      desc: "The session was very informative and helped me gain a deeper understanding of the subject matter.",
      p_name: "Zanish Khan",
      profession: "Student at Khadijatul Kubra Girls Public School",
    },
    {
      id: 4,
      heading: "Impressive Speaker",
      desc: "Skillzyy's workshops have been a game-changer for me! The hands-on approach to learning made all the difference. I loved how the instructors encouraged creativity and problem-solving. It's incredible how much I've learned and grown in such a short time. I highly recommend Skillzyy to all my peers!",
      p_name: "Pushkar Ranjan",
      profession: "St. Xavier High School",
    },
  ];
  const [activeSlide, setActiveSlide] = React.useState(1);

  const handlePrevSlide = () => {
    setActiveSlide(activeSlide === 1 ? slides.length : activeSlide - 1);
  };

  const handleNextSlide = () => {
    setActiveSlide(activeSlide === slides.length ? 1 : activeSlide + 1);
  };

  return (
    <div className="flex flex-col justify-center items-center pt-12 sm:pt-10 px-14">
      <div className=" relative ">
        {/* Slides */}
        {slides.map((slide) => (
          <div
            key={slide.id}
            className={`${
              activeSlide === slide.id ? "block" : "hidden"
            } relative flex max-w-xl flex-col items-center justify-center px-10 py-10`}
          >
            <svg
              className="absolute -top-10 -left-10 text-9xl opacity-10"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6.5 10c-.223 0-.437.034-.65.065c.069-.232.14-.468.254-.68c.114-.308.292-.575.469-.844c.148-.291.409-.488.601-.737c.201-.242.475-.403.692-.604c.213-.21.492-.315.714-.463c.232-.133.434-.28.65-.35l.539-.222l.474-.197l-.485-1.938l-.597.144c-.191.048-.424.104-.689.171c-.271.05-.56.187-.882.312c-.318.142-.686.238-1.028.466c-.344.218-.741.4-1.091.692c-.339.301-.748.562-1.05.945c-.33.358-.656.734-.909 1.162c-.293.408-.492.856-.702 1.299c-.19.443-.343.896-.468 1.336c-.237.882-.343 1.72-.384 2.437c-.034.718-.014 1.315.028 1.747c.015.204.043.402.063.539l.025.168l.026-.006A4.5 4.5 0 1 0 6.5 10zm11 0c-.223 0-.437.034-.65.065c.069-.232.14-.468.254-.68c.114-.308.292-.575.469-.844c.148-.291.409-.488.601-.737c.201-.242.475-.403.692-.604c.213-.21.492-.315.714-.463c.232-.133.434-.28.65-.35l.539-.222l.474-.197l-.485-1.938l-.597.144c-.191.048-.424.104-.689.171c-.271.05-.56.187-.882.312c-.317.143-.686.238-1.028.467c-.344.218-.741.4-1.091.692c-.339.301-.748.562-1.05.944c-.33.358-.656.734-.909 1.162c-.293.408-.492.856-.702 1.299c-.19.443-.343.896-.468 1.336c-.237.882-.343 1.72-.384 2.437c-.034.718-.014 1.315.028 1.747c.015.204.043.402.063.539l.025.168l.026-.006A4.5 4.5 0 1 0 17.5 10z"
              />
            </svg>
            <h1 className="mb-2 text-4xl font-black">
              {slide.heading}
            </h1>
            <p className="max-w-md text-justify">{slide.desc}</p>
            {/* <div className=" absolute overflow-hidden h-full w-full flex justify-center">
              <svg
                className="w-[40rem] sm:w-full shrink-0"
                viewBox="0 0 225 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.00003 57.0391C-3.60997 75.6991 2.82003 103.009 17.37 117.559C31.91 132.109 54.95 139.839 76.94 137.559C92.8 135.909 107.42 129.519 122.71 125.509C138 121.499 155.69 120.159 168.97 127.889C175.82 131.869 181.35 138.159 189.42 139.599C196.67 140.899 204.25 137.719 209.18 132.909C214.1 128.099 216.79 121.879 218.85 115.679C224.65 98.2691 226.3 79.8191 223.68 61.8391C221.84 49.2191 217.61 36.3491 207.83 26.8991C192.99 12.5491 169.11 9.13909 147.21 6.14909C127.53 3.45909 107.79 0.419095 87.87 0.589095C67.95 0.749095 47.5701 4.3391 30.9601 13.9091C14.3401 23.4691 2.05003 39.6891 2.00003 57.0391Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="-40.5"
                    y1="78.5"
                    x2="225"
                    y2="53"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#00D4FF" />
                    <stop offset="1" stopColor="#0027B1" />
                  </linearGradient>
                </defs>
              </svg>
            </div> */}
            <div className="mt-4 flex items-center rounded-xl bg-pink-600 px-6 py-2">
              <div className="ml-3">
                <p className="text-lg font-semibold text-gray-100">
                  {slide.p_name}
                </p>
                <p className="mt-px text-xs text-gray-300">
                  {slide.profession}
                </p>
              </div>
            </div>
          </div>
        ))}

        {/* Prev/Next Arrows */}
        <div className="absolute inset-0 flex">
          <div className="flex items-center justify-start w-1/2">
            <button
              className="bg-pink-200 text-pink-600 hover:text-red-500 font-bold hover:shadow-lg rounded-full w-8 h-8 -ml-6"
              onClick={handlePrevSlide}
            >
              &#8592;
            </button>
          </div>
          <div className="flex items-center justify-end w-1/2">
            <button
              className="bg-pink-200 text-pink-600 hover:text-red-500 font-bold hover:shadow rounded-full w-8 h-8 -mr-6"
              onClick={handleNextSlide}
            >
              &#8594;
            </button>
          </div>
        </div>

        {/* Buttons */}
        
      </div>
    </div>
  );
};

export default Testimonial;
