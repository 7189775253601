import React, { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );
    console.log(theme);
    useEffect(()=>{
      document.querySelector("html").setAttribute("data-theme", theme);
    },[theme]);
    const updateTheme = (newTheme) => {
      setTheme(newTheme);
      localStorage.setItem("theme", newTheme);
     
    };
  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};