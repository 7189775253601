import React from 'react'


export default function Newsletter() {
  
  function Submit(e){
    const formEle = document.querySelector("form");
    e.preventDefault();
   
    const formData = new FormData(formEle);
   
    fetch("https://script.google.com/macros/s/AKfycbw4Mvm16Xq22AnhnzhL0eBrcjRna9WUs0zjWGgHq8ea1H2OQss_VETtYbEV5q8vKtny/exec", {
      method: "POST",
      body: formData
    }).then(() => {
      // Reset the form after successful submission
      formEle.reset();
     
      // Create a message element
      const message = document.createElement('div');
      message.textContent = "Form submitted successfully!";
      message.style.display ="flex";
      message.style.color = "green";

      message.style.marginLeft = "10px";
     
      // Append the message to the form
      formEle.appendChild(message);
     
      // Remove the message after 1 second
      setTimeout(() => {
        formEle.removeChild(message);
      }, 1000);
    });
  }
 
 
  return (
    <div>
      <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
  <div className="rounded-3xl bg-base-300 py-10 px-6 sm:py-14 sm:px-12 lg:flex lg:items-center lg:p-20">
    <div className="lg:w-0 lg:flex-1">
      <h2 className="text-3xl font-bold tracking-tight text-center md:text-left">Subscribe our newsletter</h2>
      <p className="mt-4 max-w-3xl text-lg text-center md:text-left">
      Get ready to stay up-to-date with all the latest happenings, exciting updates, and insider insights from Skillzyy!
      </p>
    </div>
    <div className='flex justify-center items-center'>
    <div className="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
      <form method="post" className="form sm:flex space-y-2" onSubmit={(e) =>Submit(e)}>
        <label htmlFor="name" className="sr-only">Name</label>
        <label htmlFor="email-address"  className="sr-only">Email address</label>
        <input id="name" type="name" name="Name" autoComplete="name" className="w-full rounded-md sm:mr-3 border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-700" placeholder="Your name" />
        <input id="email-address" name="Email" type="email" autoComplete="email" className="w-full rounded-md border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-700" placeholder="Your email"/>
        <input type="checkbox" value="1" className="hidden" tabIndex="-1" autoComplete="off"/>
        <button type="submit" className="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-pink-600 px-5 py-3 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-700 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">Subscribe</button>
      </form>
      {/* <p className="mt-3 text-sm text-center md:text-left">
        We care about the protection of your data. Read our
        <a href="" className="font-medium underline text-center md:text-left">Privacy Policy.</a>
      </p> */}
    </div>
    </div>
  </div>
</div>
    </div>
   
   
  )
}
