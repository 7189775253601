import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Hero from '../components/hero/Hero'
import Feature from '../components/hero/Feature'
import Newsletter from '../components/footer/Newsletter'
import Surv from '../components/survey/Surv'
import Courses from '../components/carousel/Courses'
import Testimonial from '../components/hero/Testimonial'

export default function Home() {
  return (
    <div>
      <Navbar/>
      <Hero/>
      <Surv/>
      <Feature/>
      {/* <Courses/> */}
      <Testimonial/>
      <Newsletter/>
      <Footer/>
    </div>
  )
}
