import React from "react";

const Vision = () => {
  return (
    <section className=" bg-base-200 mx-6 md:mx-auto    my-20 flex max-w-3xl sm:max-w-6xl  flex-col rounded-3xl border-pink-600 px-4 py-10 sm:border-8 sm:px-10 lg:max-w-screen-xl lg:flex-row">
      <div className="">
        <h2 className="mb-4 text-4xl font-medium">
          <span className="text-pink-600">Our Vision</span>
        </h2>
        <p className="mb-6 text-justify">
          Our vision at Skillzy is to redefine education for school students,
          creating a pathway to limitless possibilities. We envision a future
          where every student, regardless of background or circumstance, has the
          opportunity to unlock their full potential and shape their own
          destiny. As the premier destination for skill development, we strive
          to cultivate a culture of curiosity, creativity, and resilience among
          students. Our goal is to inspire and empower the next generation of
          leaders, innovators, and changemakers who will drive positive
          transformation in their communities and beyond.
        </p>
        <div className=" ">
          <div className="flex flex-col sm:flex-row sm:space-x-6">
            <div className="sm:w-1/3 bg-yellow-500 text-primary-content rounded-lg mb-6 sm:mb-0">
              <p className="p-3 text-justify">
              Our goal is to inspire and empower the next generation
              </p>
            </div>
            <div className="sm:w-1/3 bg-pink-600  text-primary-content rounded-lg ">
              <div className="">
                {/* <h2 className="card-title">Card title!</h2> */}
                <p className="p-3 text-white text-justify">
                Skillzy redefine education for school students
                </p>
                <div className="card-actions justify-end">
                  {/* <button className="btn">Buy Now</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex justify-center items-center ">
        <img
          className=""
          src="https://ideascale.com/wp-content/uploads/2023/07/Vision-Board-Ideas.png"
          alt=""
        />
      </div>
    </section>
  );
};

export default Vision;
