import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
}  from  "react-router-dom"

import Home from './pages/Home';
import { ThemeProvider } from './ThemeContext';
import About from './pages/About';
import SchoolContact from './pages/SchoolContact';
import PageNotFound from './pages/PageNotFound';
import Services from './components/services/Services';
import ServicesPage from './pages/ServicesPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path:"/about",
    element: <About/>
  },
  {
    path:"/school-contact",
    element: <SchoolContact/>
  },
  {
    path:"/services",
    element: <ServicesPage/>
  },
  {
    path:"*",
    element: <PageNotFound/>
  }
])

function App() {
  return (
    <ThemeProvider>
    <div>
      <RouterProvider router = {router} />
    </div>
    </ThemeProvider>
  );
}

export default App;
