import React from 'react'
import { FaLaptopCode } from "react-icons/fa";
import { MdTravelExplore } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";

export default function Feature() {
  return (
    <div>
      <section className="">
        <div className="px-6 mx-auto rounded max-w-screen-xl py-12 sm:py-20 lg:px-16 ">

          <div className="space-y-8 text-center md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div className='bg-base-200 p-6 rounded shadow-xl transition duration-300 hover:scale-105 hover:bg-pink-600 hover:text-white'>
              <div className='flex justify-center items-center'>
                <div className="bg-pink-200 flex justify-center items-center mb-2 w-12 h-12 rounded  lg:h-12 lg:w-12">
                  <FaLaptopCode className='w-6 h-6 text-pink-600' />
                </div>
              </div>
              <h3 className="mb-2 text-lg font-bold text-center ">Expert-Led Workshops</h3>
              <p className=" text-center text-sm">Our workshops are led by industry experts who bring real-world experience and insights to the classroom. Learn from the best and gain practical skills that are in demand in today's job market.</p>
            </div>
            <div className='bg-base-200 p-6 rounded shadow-xl transition duration-300 hover:scale-105 hover:bg-pink-600 hover:text-white'>
              <div className='flex justify-center items-center'>
                <div className="flex justify-center items-center mb-2 w-12 h-12 rounded bg-pink-200 lg:h-12 lg:w-12">
                  <MdTravelExplore className="w-6 h-6 text-pink-600" />
                </div>
              </div>
              <h3 className="mb-2 text-lg font-bold text-center">Hands-On Projects</h3>
              <p className=" text-center text-sm">Get ready to roll up your sleeves and dive into hands-on projects that challenge and inspire. From coding challenges to design projects, our hands-on approach ensures you're ready to tackle any task with confidence.</p>
            </div>
            <div className='bg-base-200 p-6 rounded shadow-xl transition duration-300 hover:scale-105 hover:bg-pink-600 hover:text-white'>
              <div className='flex justify-center items-center'>
                <div className="flex justify-center items-center mb-2 w-12 h-12 rounded bg-pink-200 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <TbCertificate className='w-6 h-6 text-pink-600' />
                </div>
              </div>
              <h3 className="mb-2 text-lg font-bold text-center">Flexible Learning Options</h3>
              <p className=" text-center text-sm">Whether you prefer in-person workshops or online courses, we've got you covered. Choose the learning format that works best for you and take control of your learning journey.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}