import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { CiMail } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import Navbar from '../components/navbar/Navbar';


export default function SchoolContact() {
  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();


    emailjs
      .sendForm(
        'service_6icf2xs',
        'template_bh8zgws',
        form.current, {
        publicKey: 'MZELySQhUYM_7puT8',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          e.target.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <>
      <Navbar />
      <section className="body-font relative bg-base-100 pt-10">
        <div className="container mx-auto px-5 py-24">
          <div className="mb-12 flex w-full flex-col text-center">
            <h1 className="title-font mb-4 text-2xl text-pink-500 font-medium sm:text-3xl">APPOINTMENT</h1>
            <p className="mx-auto text-base leading-relaxed lg:w-2/4">
              Contact us today to explore partnership opportunities and bring Skillzyy's engaging learning experiences to your school.</p>
          </div>


          <div className="mx-auto md:w-2/3 lg:w-1/2">
            <div className="-m-2 flex flex-wrap">


              {/* form */}
              <form ref={form} onSubmit={sendEmail} className='flex flex-wrap'>
                <div className="w-1/2 p-2">
                  <div className="relative">
                    <input type="text" id="name" name="user_name" className="peer w-full rounded border border-yellow-500 bg-base-300 bg-opacity-40 py-1 px-3 text-base leading-8 text-yellow-600 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-yellow-500 focus:bg-base-200 focus:ring-2 focus:ring-yellow-600" placeholder="Name" />
                    <label htmlFor="name" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-pink-600 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-pink-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-pink-600">Name</label>
                  </div>
                </div>
                <div className="w-1/2 p-2">
                  <div className="relative">
                    <input type="text" id="email" name="user_email" className="peer w-full rounded border border-yellow-500 bg-base-300 bg-opacity-40 py-1 px-3 text-base leading-8 text-yellow-600 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-yellow-500 focus:bg-base-200 focus:ring-2 focus:ring-yellow-600" placeholder="Email" />
                    <label htmlFor="Email" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-pink-600 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-pink-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-pink-600">Email</label>
                  </div>
                </div>
                <div className="mt-4 w-full p-2">
                  <div className="relative">
                    <textarea id="message" name="message" className="peer w-full rounded border border-yellow-500 bg-base-300 bg-opacity-40 py-1 px-3 text-base leading-8 text-yellow-600 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-yellow-500 focus:bg-base-200 focus:ring-2 focus:ring-yellow-600" placeholder="Message"></textarea>
                    <label htmlFor="name" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-pink-600 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-pink-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-pink-600">Message</label>
                  </div>
                </div>
                <div className="w-full p-2">
                  <button className="mx-auto flex rounded border-0 bg-pink-500 py-2 px-8 text-lg text-white hover:bg-pink-600 focus:outline-none">Submit</button>
                </div>
              </form>
              {/* footer */}
              <div className="mt-8 w-full border-t border-yellow-600 p-2 pt-8 text-center">
                <a className="text-pink-500">learn@skillzyy.in</a>
                <p className="my-5 leading-normal text-gray-500">www.skillzyy.in <br /></p>
                <span className="inline-flex">
                  <a className="text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-5 w-5" viewBox="0 0 24 24">
                      <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-5 w-5" viewBox="0 0 24 24">
                      <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-5 w-5" viewBox="0 0 24 24">
                      <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                      <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-5 w-5" viewBox="0 0 24 24">
                      <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                    </svg>
                  </a>
                </span>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  )
}
