import React from "react";

const Services = () => {
  return (
    <div className="container pt-32 relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0">
      <h2 className="text-3xl font-extrabold leading-tight text-center ">
        Services
      </h2>
      <p className="mb-12 text-lg text-gray-500 text-center">
        Here is a few of the awesome Services we provide.
      </p>
      <div className="w-full">
        <div className="flex flex-col w-full mb-10 sm:flex-row">
          <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
            <div className="relative h-full ml-0 mr-0 sm:mr-10">
              <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-blue-600 rounded-lg"></span>
              <div className="relative h-full p-5 bg-base-100 border-2 border-blue-600 rounded-lg">
                <div className="flex items-center -mt-1">
                  <h3 className="my-2 ml-3 text-lg font-bold ">
                    Skill Development Workshops
                  </h3>
                </div>
                <p className="mt-3 mb-1 text-xs font-medium text-indigo-500 uppercase">
                  ------------
                </p>
                <p className="mb-2 text-gray-600">
                  Engaging workshops tailored to equip students with practical
                  skills in coding, web development, graphic design, and more.
                  Led by industry experts, these workshops provide hands-on
                  learning experiences that prepare students for the demands of
                  the modern world.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <div className="relative h-full ml-0 md:mr-10">
              <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-pink-600 rounded-lg"></span>
              <div className="relative h-full p-5 bg-base-100 border-2 border-pink-600 rounded-lg">
                <div className="flex items-center -mt-1">
                  <h3 className="my-2 ml-3 text-lg font-bold ">
                    Customized Curriculum Integration in Schools
                  </h3>
                </div>
                <p className="mt-3 mb-1 text-xs font-medium text-pink-600 uppercase">
                  ------------
                </p>
                <p className="mb-2 text-gray-600">
                  Collaborate with us to integrate our skill development
                  curriculum seamlessly into your school's existing programs. We
                  work closely with educators to tailor our offerings to meet
                  the specific needs and goals of your students and curriculum.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mb-5 sm:flex-row">
          <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
            <div className="relative h-full ml-0 mr-0 sm:mr-10">
              <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-violet-800 rounded-lg"></span>
              <div className="relative h-full p-5 bg-base-100 border-2 border-violet-800 rounded-lg">
                <div className="flex items-center -mt-1">
                  <h3 className="my-2 ml-3 text-lg font-bold">
                    Career Readiness Programs
                  </h3>
                </div>
                <p className="mt-3 mb-1 text-xs font-medium text-blue-400 uppercase">
                  ------------
                </p>
                <p className="mb-2 text-gray-600">
                  Prepare students for success beyond the classroom with our
                  career readiness programs. From internship opportunity
                  assistance, we provide the resources and support students need
                  to launch their careers with confidence. Explore career
                  pathways and gain insights into various industries to make
                  informed decisions about their future.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
            <div className="relative h-full ml-0 mr-0 sm:mr-10">
              <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-yellow-400 rounded-lg"></span>
              <div className="relative h-full p-5 bg-base-100 border-2 border-yellow-400 rounded-lg">
                <div className="flex items-center -mt-1">
                  <h3 className="my-2 ml-3 text-lg font-bold ">
                    Professional Development
                  </h3>
                </div>
                <p className="mt-3 mb-1 text-xs font-medium text-yellow-400 uppercase">
                  ------------
                </p>
                <p className="mb-2 text-gray-600">
                  Empower your educators with professional development programs
                  designed to enhance their teaching skills and stay up-to-date
                  with the latest trends in education and technology. Our
                  workshops and training sessions equip educators with the tools
                  and knowledge they need to inspire and engage their students
                  effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <div className="relative h-full ml-0 md:mr-10">
              <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-green-500 rounded-lg"></span>
              <div className="relative h-full p-5 bg-base-100 border-2 border-green-500 rounded-lg">
                <div className="flex items-center -mt-1">
                  <h3 className="my-2 ml-3 text-lg font-bold ">
                    Consultation Services
                  </h3>
                </div>
                <p className="mt-3 mb-1 text-xs font-medium text-green-500 uppercase">
                  ------------
                </p>
                <p className="mb-2 text-gray-600">
                  Need guidance on implementing skill development initiatives in
                  your school? Our consultation services provide expert advice
                  and support every step of the way. From program planning to
                  evaluation, we're here to help you create impactful learning
                  experiences for your students.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
