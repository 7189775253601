import React from "react";
import img1 from "../../assets/images/iqra.jpeg"
import img2 from "../../assets/images/anku.jpg"
import img3 from "../../assets/images/shubham.jpeg"
import img4 from "../../assets/images/yash.jpeg"

const Team = () => {
    return (
        <section className=" py-4">
          <div className=" px-12 sm:px-20  flex flex-col sm:flex-col items-center justify-center">
            {/* Heading */}
            <div className="relative   mb-10">
              <h2 className="text-center font-serif text-3xl font-bold  md:text-4xl">Meet the Team</h2>
            </div>
            {/* /Heading */}
            <div className="grid gap-6 sm:grid-cols-2 sm:gap-12 lg:grid-cols-3 xl:grid-cols-4 xl:gap-16">
              {/* Article 1 */}
              <article>
                <a className="block rounded-lg bg-gradient-to-r from-orange-500 via-orange-400 to-yellow-400 p-2 transition hover:scale-105" href="#">
                  <h2 className="mx-4 mt-4 mb-4 font-serif text-2xl font-semibold text-white">Person behind this idea to empower students.</h2>
                  <div className="flex items-center rounded-md px-4">
                    <img className=" w-14 rounded-full object-cover" src={img1} alt="Iqra Zafar" />
                    <p className=" ml-4 w-56">
                      <strong className="block text-lg font-medium text-white">Iqra Zafar</strong>
                      <span className="text-xs text-white"> Founder of Skillzyy</span>
                    </p>
                  </div>
                </a>
              </article>
              {/* /Article 1 */}
              {/* Article 2 */} 
              <article>
                <a className="block rounded-lg bg-gradient-to-r from-blue-500 via-sky-400 to-cyan-400 p-2 transition hover:scale-105" href="#">
                  <h2 className="mx-4 mt-4 mb-4 font-serif text-2xl font-semibold text-white">I'm all about crafting engaging content, and building a vibrant community</h2>
                  <div className="flex items-center rounded-md px-4 ">
                    <img className="w-14 rounded-full object-cover" src={img2} alt="Ankita Ranjan" />
                    <p className="ml-4 w-56">
                      <strong className="block text-lg font-medium text-white">Ankita Ranjan</strong>
                      <span className="text-xs text-white"> Frontend Developer, Social Media Manager at Skillzyy </span>
                    </p>
                  </div>
                </a>
              </article>
              {/* /Article 2 */}
              {/* Article 3 */}
              <article>
                <a className="block rounded-lg bg-gradient-to-r from-purple-500 via-indigo-400 to-indigo-400 p-2 transition hover:scale-105" href="#">
                  <h2 className="mx-4 mt-4 mb-4 font-serif text-2xl font-semibold text-white">I'm here to make education awesome for everyone.</h2>
                  <div className="flex items-center rounded-md px-4 ">
                    <img className="w-14 rounded-full object-cover" src={img3} alt="Shubham Kumari" />
                    <p className="ml-4 w-56">
                      <strong className="block text-lg font-medium text-white">Shubham Kumari</strong>
                      <span className="text-xs text-white"> Co-Founder at Skillzyy </span>
                    </p>
                  </div>
                </a>
              </article>
              {/* /Article 3 */}
              {/* Article 4 */}
              <article>
                <a className="block rounded-lg bg-gradient-to-r from-fuchsia-500 via-pink-500 to-rose-400 p-2 transition hover:scale-105" href="#">
                  <h2 className="mx-4 mt-4 mb-4 font-serif text-2xl font-semibold text-white">I'm dedicated to creating top-notch courses that inspire, educate, and empower.</h2>
                  <div className="flex items-center rounded-md px-4">
                    <img className="w-14  rounded-full object-cover" src={img4} alt="Yash Pradhan" />
                    <p className="ml-4 w-56">
                      <strong className="block text-lg font-medium text-white">Yash Pradhan</strong>
                      <span className="text-xs text-white"> You-Tuber </span>
                    </p>
                  </div>
                </a>
              </article>
              {/* /Article 4 */}
            </div>
          </div>
        </section>
      );
};

export default Team;
