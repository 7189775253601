import React from "react";

const Mission = () => {
  return (
    <section className=" flex body-font  dark:bg-base-200 my-10 items-center justify-center ">
      <div className="container  flex   md:flex-row flex-col items-center my-10 md:mx-14">
        <div className="lg:max-w-lg lg:w-full mb-5 md:mb-0 ">
          <img
            className="object-cover object-center rounded hidden sm:flex "
            alt="hero"
            src="https://www.svgrepo.com/show/490900/hot-air-balloon.svg"
          />
        </div>
        <div className="lg:flex-grow mt-3 md:mt-0 md:w-1.5/2  flex flex-col md:items-end md:text-center mb-16 md:mb-0 items-center text-center">
          <h1 className="text-4xl font-extrabold leading-9 tracking-tight mb-6 text-pink-600  sm:text-4xl sm:leading-10 md:text-5xl md:leading-normal">
            Our Mission
          </h1>
          <div className="my-1 mx-6 sm:mx-0">
            <div className="flex items-center rounded-md border border-base-300 bg-base-200 px-2 py-3 shadow-lg text-justify">
              <p className="mx-3">
                At Skillzy, our mission is to ignite the potential of school
                students by providing them with comprehensive skill development
                programs.
              </p>
            </div>
            <div className="mt-2 flex items-center rounded-md border border-base-300 bg-base-200 px-2 py-3 shadow-lg text-justify">
              <p className="mx-3 ">
                We empower students with practical knowledge, essential skills,
                and immersive experiences, preparing them not just for academic
                success, but for the dynamic challenges of the future.
              </p>
            </div>
            <div className="mt-2 flex items-center rounded-md border border-base-300 bg-base-200 px-2 py-3 shadow-lg text-justify">
              <p className="mx-3">
                Through engaging and accessible education, we aim to equip
                students with the confidence and capabilities to excel in higher
                education, secure meaningful internships, pursue fulfilling
                careers, and even embark on entrepreneurial ventures.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
