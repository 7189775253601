import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import img from "../../assets/images/girl.png"

const Typewriter = ({ words }) => {
	const [index, setIndex] = useState(0);
	const [charIndex, setCharIndex] = useState(0);
	const [currentWord, setCurrentWord] = useState("");
	const [isDeleting, setIsDeleting] = useState(false);

	useEffect(() => {
		const type = () => {
			setCurrentWord(words[index].name);

			if (isDeleting) {
				setCharIndex(prevCharIndex => prevCharIndex - 1);
				if (charIndex === 0) {
					setIsDeleting(false);
					setIndex(prevIndex => (prevIndex + 1) % words.length);
				}
			} else {
				setCharIndex(prevCharIndex => prevCharIndex + 1);
				if (charIndex === currentWord.length) {
					setIsDeleting(true);
				}
			}
		};

		const timer = setTimeout(type, 100);

		return () => clearTimeout(timer);
	}, [charIndex, index, isDeleting, currentWord, words]);

	return (
		<span id="typewriter" className={`text-2xl before:animate-typing ${words[index].color} font-bold`}>
			{currentWord.substring(0, charIndex)}
		</span>
	);
};

export default function Hero() {
	const words = [
		{ name: "Coding", color: "text-pink-600" },
		{ name: "Web Development", color: "text-blue-500" },
		{ name: "Graphic Designing", color: "text-green-500" },
		{ name: "Video Editing", color: "text-yellow-500" },
		{ name: "IOT", color: "text-purple-500" }
	];

	return (
		<div>
			<section className="bg-base-200 pt-20 px-6">
				<div className="container flex flex-col justify-center mx-auto py-10 sm:py-36  lg:flex-row lg:justify-around">
					<div className="flex flex-col justify-center text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
						<h1 className="text-3xl font-bold leadi sm:text-5xl">
							<span className='text-2xl sm:text-4xl'>Let's Team up</span><br />
							<span className="dark:text-pink-500 text-4xl sm:text-6xl">Learn Cool Stuff</span>, and make some magic together.
						</h1>
						<p className="mt-6 text-lg sm:mb-12">
							Whether you wanna master&nbsp;
							<br className='block sm:hidden' />
							<Typewriter words={words} className="break-all sm:break-normal" /><span className='text-3xl'>|</span>
							<p>we've got just the thing for you!</p>
						</p>

						<div className="flex items-center justify-center  space-x-4 lg:justify-start py-4 sm:py-0 ">
							<Link to = "/school-contact">
							<button className="relative inline-flex items-center justify-center sm:justify-start w-30 sm:w-auto px-6 py-3  overflow-hidden font-medium transition-all bg-pink-600 rounded-xl group cursor-pointer">
								<span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-red-700 rounded group-hover:-mr-4 group-hover:-mt-4">
									<span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-gray-100"></span>
								</span>
								<span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full translate-y-full bg-red-600 rounded-2xl group-hover:mb-12 group-hover:translate-x-0"></span>
								<span className="relative sm:w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">I'm School</span>
							</button>
							</Link>
							<button href="#_" className="relative inline-flex items-center justify-center sm:justify-start w-30 sm:w-auto px-6 py-3 overflow-hidden font-medium transition-all bg-yellow-500 rounded-xl group cursor-pointer">
								<span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-yellow-600 rounded group-hover:-mr-4 group-hover:-mt-4">
									<span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-gray-100"></span>
								</span>
								<span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full translate-y-full bg-yellow-600 rounded-2xl group-hover:mb-12 group-hover:translate-x-0"></span>
								<span className="relative sm:w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">Learner</span>
							</button>						</div>
					</div>
					<div className="flex items-center justify-center mt-8 hidden lg:block lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
						<img src={img} alt="" className="object-contain h-72 sm:h-80 lg:h-96" />
						{/* <a href="https://icons8.com/illustrations/author/zD2oqC8lLBBA" className="object-contain h-72 sm:h-80 lg:h-96">Icons 8</a> from <a href="https://icons8.com/illustrations">Ouch!</a> */}
					</div>
				</div>
			</section>
		</div>
	)
}
