import React from 'react';
import Hero from '../components/about/Hero';
import Navbar from '../components/navbar/Navbar';
import Vision from '../components/about/Vision';
import Mission from '../components/about/Mission';
import Footer from '../components/footer/Footer';
import Team from '../components/about/Team';

const About = () => {
  return (
    <div>
    <Navbar/>
      <Hero />
      <Vision/>
      <Mission/>
      <Team/>
      <Footer/>
    </div>
  );
}

export default About;
