import React from "react";
import img from "../../assets/images/team.jpeg";

const Header = () => {
  return (
    <header className="relative ">
      <div className="bg-base-200 w-full py-32 sm:py-48"></div>
      <div className="flex flex-col items-center -mt-40 sm:-mt-60 px-4">
        <h1 className="text-3xl font-bold text-center sm:text-5xl mb-8 sm:mb-16">
        To get to know about us, come and meet us

        </h1>
        
        <img
          src={img}
          className="object-cover w-2/3 h-30 sm:h-60 rounded-l-full rounded-br-full  shadow-lg"
          alt="Team"
        />
      </div>
    </header>
  );
};

export default Header;
